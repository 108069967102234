import { memo, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { ItemTypes } from './ItemTypes.js'
import {Picture} from './Picture.js'


function getStyles(left, top, isDragging) {
  const transform = `translate3d(${left}px, ${top}px, 0)`
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
  }
}
export const DraggableBox = memo(function DraggableBox(props) {
  const { 
    id, url, title, top, left, 
    rotation, zIndex, onMap, rotateImage, deleteImage } = props
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, left, top, title },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, title],
  )
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])
  return (
    <div
      ref={drag}
      style={getStyles(left, top, isDragging)}
      role="DraggableBox"
    >
      <Picture 
        id={id} 
        url={url} 
        title={title} 
        top={top}
        left={left} 
        rotation={rotation}
        zIndex={zIndex}
        onMap={onMap}
        rotateImage={rotateImage}
        deleteImage={deleteImage}
      />
    </div>
  )
})
